import React from "react";

export default function Grievance() {
    return (
    <main className='bg-white p-4'>
        <h3 className="text-3xl font-semibold my-10">Grievance Policy</h3>
        <p className="pb-4">We have developed, implemented, and fully inform participants of grievance policies and procedures that provide for receipt of written grievances from participants.  We shall document the receipt, investigation, and any action taken as to the written grievance.</p>
        <p className="pb-4">A participant with a grievance about any part of our service delivery should first contact the Program Director to address their complaints. Please write out your grievance, including as much detail as possible (dates, times, groups, etc.). The more details provided, the more comprehensive our investigation can be, and submit to the program director by e-mail or postal mail. You may also call the Program Director to discuss informally.</p>
        <p className="pb-4">Please Contact:</p>
        <p  className="pb-4">Krystal Duff, MA, Executive Program Director<br/>
            krystal@bridges2success.com (503) 508-4893<br/>
            PO Box 15118 Salem, OR 97309</p>
            <p className="pb-4">We understand that there are a number of reasons why a participant may not be comfortable dealing with a grievance directly with the Program Director.  Thus, the following alternative action step(s) may be taken.</p>
        <ul className='pl-6 pb-4'>
            <li className='list-disc'>If the participant is on supervised probation, with a parole/probation officer, the participant may contact the Local Supervisory Authority, at their Parole & Probation office. The individual to contact would be the head of the Domestic Violence Unit.</li>
            <li className='list-disc'>If the participant is on bench probation to the court, they are to contact the Trial Court Administrators Office.</li>
            <li className='list-disc'>You may always contact your referral source and/or their supervisor as well.</li>
        </ul>
        <p className="pb-4">If you need assistance, we have official grievance forms available at all offices and you can also request one by e-mail at office@bridges2success.com. However, participants are not required to utilize the official form to file a grievance. All grievances will be addressed within 30 days whether they are placed in writing or not.<br/> There will be no change in the status of a client in this program as a result of the client exercising their right to file a grievance.  Retaliation, adverse action against, or treatment of, an individual(s) by staff because they have exercised their right to file a grievance will not be tolerated by management.</p>
    </main>
        
    );
}