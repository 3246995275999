import React from "react";

export default function Survivors() {
    return (
    <main className='bg-white p-4'>
        <h3 className="text-3xl font-semibold my-10">Survivor’s Info</h3>
        <p className="font-semibold">!!WARNING!! If you are in immediate danger, please Call 911</p>
        <p>Or call the National Domestic Violence Hotline at 1 (800) 799-SAFE</p>
        <p className="mt-6">There are many ways that computers record everything you do on the computer and on the Internet.</p>
        <p>Try using a safer computer such as one at the public library.</p>
        <p>E-mail may not be a safe way to communicate.</p>
        <p>Traditional corded phones may be safer than cellular phones as well.</p>
        <p className="mt-10 font-semibold">Myths & Facts</p>
        <p>About Domestic Violence</p>
        <ul className='pl-6'>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Domestic violence is not common.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span>  Every 9 seconds in the United States, a man beats a woman. In Oregon, crisis service hotlines annually receive more than 50,000 emergency calls and 37,000 non-emergency calls from survivors of domestic violence.</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Domestic violence happens only in low-income families.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Domestic violence happens in all kinds of families, rich and poor, urban, suburban and rural, in every part of the country, in every racial, religious and age group.</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Alcohol and drugs cause domestic violence.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Alcohol and drugs do not cause domestic violence. Domestic violence is a choice. Many abusers will make sure they have alcohol or drugs on hand, in order to use them as an excuse for their actions. Abusers will also claim their actions resulted because they could not have the alcohol or drugs.</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Domestic violence is an anger control issue.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Domestic violence has nothing to do with anger. Anger is a tool abusers use to get what they want. We know abusers are actually very much in control because they can stop when someone knocks on the door or the phone rings; they often direct punches and kicks to parts of the body where the bruises are less likely to show; and they are not abusing everyone who makes them "angry", but waits until there are no witnesses and abuses the one he says he loves.</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Abusers and/or victims have low self-esteem.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Abusers do not have low self-esteem. They believe they are entitled to have power and control over their partner. Abusers will pretend to have low-self esteem, if it will make others believe the violence is not their fault. (see In the Mind of the Abuser for more information on this subject). <span className="font-semibold">FACT:</span> Survivors of abuse may have had great self-esteem at the beginning of the relationship, but the abuser uses emotional abuse: calling her names, putting her down, telling her it is all her fault, in order to destroy her self-esteem. Some abusers look for women with low self-esteem, as they believe she will be more likely to blame herself and less likely to report his behavior. Other abusers will seek women with high self-esteem, as they may represent a greater challenge to control over time.</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Most assaults are really just a couple of slaps and they are not really harmful.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> More than 30 percent of hospital emergency-room admissions are women who have been abused. Domestic violence is the single greatest cause of injury to women in the United States.</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Domestic violence happens only once or twice in a relationship.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Abusers usually escalate violent behaviors in frequency and intensity over time.</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Some women want to be beaten. They ask for it. They deserve it. Some women go from abuser to abuser - it must be something about them.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> No one deserves to be abused. Everyone has the right to live free of violence. No one would want to have their partner be abusive. Women who find that their second or third partner are abusers will often be blamed by others for the violence -" it must be something about her" or she will blame herself -"I always seem to pick abusers." In reality, the abuser uses the tactic of charm early in the relationship to find out that she was previously abused. He uses this information to blame her for the violence - "see it must be something that you are doing wrong, or there would not have been two of us" or to silence her - "you are not going to tell anyone, because if you do they will never believe you because you said that before."</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Children aren't aware of the violence in their home.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Studies show that most children are aware of the violence directed at their mother. See Children's Issues for ways you can support children who witness domestic violence.</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Children are not at risk for being hurt or injured.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Men who abuse their partners are more likely to abuse the children in the home. Domestic violence is the number one predictor for child abuse. Subjecting children to an environment full of violent actions and hateful words is not being a "good dad."</li>
            <li className='list-disc'><span className="font-semibold">MYTH:</span> Boys who witness violence will grow up to be abusers.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Studies have found that 30% of male child witnesses choose to become abusers as adults. This means that 70% do not become abusers and are committed to ending the cycle of violence in their lives. The majority of children, male and female, who witness domestic violence become advocates for children when they grow up; committed to raising their children without the use of violence and going into professions where they work to end violence against all children. Young men in our society must never feel they are destined to become violent. We send a dangerous message to young men and boys when we imply they are fated to become violent and we give abusers an excuse for their behavior.</li>
        </ul>
        <p className="font-semibold">More Facts:</p>
        <ul className='pl-6'>    
            <li className='list-disc'><span className="font-semibold">FACT:</span>  Domestic violence is a crime. It is against the law for anyone to physically harm or harass another person. In Oregon, the law says police shall arrest a person who they have reason to believe has abused another person.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Domestic violence may lead to murder. Three-quarters of all women who are murdered are murdered by their husbands, ex-husbands or domestic partners. In our community, nine women and children were murdered between 1999 and February of 2003.</li>
            <li className='list-disc'><span className="font-semibold">FACT:</span> Domestic violence costs the U.S. economy an estimated $3 to $5 billion annually in job absenteeism and another $100 million annually in medical expenses.</li>
        </ul>
        
    </main>
        
    );
}